// src/components/PrivateRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

const Private = ({ children }) => {
  const token = localStorage.getItem('token');
  console.log('PrivateRoute - token:', token); // Debugging line

  return token ? children : <Navigate to="/login" />;
};

export default Private;
