
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

const Homepost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const post = location.state?.selectedPost;

  if (!post) {
    return <p>No post details available.</p>;
  }

  return (
    <>
    <style dangerouslySetInnerHTML={{ __html: "body { background-color:white; }" }} />

      <Navbar />
      <section className="hero-section" id="section_1">
        <div className="video-wrap">
          <img src={post.photo} alt={post.name} className="custom-video" id="imgym" />
        </div>
      </section>
      <section className="about-section">
        <div className="container">
        <h4 className="radiant text-uppercase ">{post.name}</h4>        
          <hr />
        </div>
      </section>

      <section>
        <div className="container">      
            <div dangerouslySetInnerHTML={{ __html: post.description }}  style={{ textAlign: 'justify', marginBottom:'5%' }} />    
        </div>
      </section>
      <Footer />

    </>

  );
}

export default Homepost;
