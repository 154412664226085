import React, { useState, useEffect } from 'react';
import Api from '../components/Api';
import Unav from '../components/Unav';
import Footer from '../components/Footer';
import Code from '../components/Code';
import Applyform from '../components/Applyform';
function Ucard() {
  
  const [userDetail, setUserDetail] = useState({});
  const [message, setMessage] = useState('');
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  useEffect(() => {
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem('userDetails'))?.phone || '';
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          const result = response.data;
          if (result.message === 'User Detail Fetch Successfully') {
            setUserDetail(result.userDetail);
          } else {
            setMessage('Failed to fetch user data.');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setMessage('An error occurred while fetching user data.');
        }
      }
    };
    fetchUserData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const response = await Api.post('/access-card', {
        name: userDetail.name,
        phone: userDetail.phone,
        email: userDetail.email,
        role: userDetail.role,
        flat: userDetail.flat,
        tower: userDetail.tower,
        remark,
      });
      const result = response.data;
      if (result.message === 'Form submitted successfully') {
        setSuccess(result.message);
        setRemark('');
      } else {
        setError(result.message || 'Submission failed');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };
  const [selectedOption, setSelectedOption] = useState('');
  const [showTextField, setShowTextField] = useState(false);
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowTextField(selectedValue === 'other');
  };
  return (
    <>
    
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <Applyform />
          <div className="row mt-2 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant">Apply Access Card</h5>
                  <hr />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-3 col-12">
                      <label className="mt-1 mb-2">
                        <b>Do you have an Access Card?</b> <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-lg-3 col-5 mt-2">
                      <select
                        className="form-select"
                        id="exampleFormControlSelect1"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option value="">Select...</option>
                        <option value="option1">No</option>
                        <option value="other">Yes</option>
                      </select>
                    </div>
                    {showTextField && (
                      <div className="col-lg-6 col-7 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={userDetail.access_cardno || ''}
                          id="exampleFormControlInput1"
                          placeholder="Enter Number"
                          disabled
                        />
                      </div>
                    )}
                  </div>
                  {/* Form Inputs */}
                  <div className="row">
                    {/* Role */}
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label className="mt-2"><b>I am an/a</b></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input
                            type="text"
                            className="w-100 form-control text-uppercase"
                            value={userDetail.role || ''}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* Name */}
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"><b>Name</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input
                            className="w-100 form-control text-uppercase"
                            type="text"
                            name="name"
                            value={userDetail.name || ''}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* Phone */}
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"><b>Phone</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <div className="row">
                            <div className="col-4">
                              <Code />
                            </div>
                            <div className="col-8">
                              <input
                                className="w-100 form-control text-uppercase"
                                type="text"
                                name="phone"
                                value={userDetail.phone || ''}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Email */}
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"><b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input
                            className="form-control text-uppercase"
                            type="text"
                            id="account-fn"
                            value={userDetail.email || ''}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* Flat Number */}
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-3">
                          <label htmlFor="account-fn"><b>Flat Number</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input
                            className="form-control text-uppercase"
                            type="text"
                            id="account-fn"
                            value={userDetail.flat || ''}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* Tower */}
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"><b>Tower</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input
                            className="form-control text-uppercase"
                            type="text"
                            id="account-fn"
                            value={userDetail.tower || ''}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* Remarks */}
                    <div className="col-lg-6 col-12 mt-3 mb-3 form-group">
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <label htmlFor="remark"><b>Remarks:</b></label>
                        </div>
                        <div className="col-lg-12 col-12 mt-2">
                          <textarea
                            className="form-control"
                            id="remark"
                            name="remark"
                            rows={3}
                            placeholder="Enter Remarks"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Submit Button */}
                
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-lg-3 col-12 mt-3 mb-3">
                      <button className="bg-primary text-white form-control text-uppercase w-100" type="submit">
                      {loading ? 'Loading...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                  
                    {/* Display success or error message */}
                    {error && <div className="alert alert-danger text-center p-2 mt-2  ">{error}</div>}
                    {success && <div className="alert alert-success text-center p-2  mt-2 ">{success}</div>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Ucard;
