import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AutoLogout = () => {
  const [isActive, setIsActive] = useState(true); // Track user activity
  const navigate = useNavigate();

  // Auto logout after 10 minutes (600000 ms)
  const logoutAfterInactivity = 600000; 

  useEffect(() => {
    // Check if user is logged in by looking for the token in localStorage
    const token = localStorage.getItem('token');
    if (!token) {
      return; // If no token, don't activate auto-logout
    }

    let timeout;

    const handleActivity = () => {
      clearTimeout(timeout); // Reset the timeout when user interacts
      setIsActive(true); // Set user as active
      timeout = setTimeout(() => {
        // Perform logout
        logoutUser();
      }, logoutAfterInactivity);
    };

    const logoutUser = () => {
      // Remove user details from storage (localStorage, cookies, etc.)
      localStorage.removeItem('token'); // Assuming token is stored here
      // Navigate to login page
      navigate('/login');
    };

    // Event listeners for user interactions
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Set initial timeout when component mounts
    timeout = setTimeout(logoutUser, logoutAfterInactivity);

    // Clean up event listeners and timeout
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [navigate]);

  return null;
};

export default AutoLogout;
