import React, { useState, useEffect } from 'react';
import Api from "../components/Api";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Code from "../components/Code";
import Applyform from "../components/Applyform";

function Urfid() {
  const [userDetails, setUserDetails] = useState({});
  const [message, setMessage] = useState("");
  
  useEffect(() => {
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem("userDetails"))?.phone || "";
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          const result = response.data;
          if (result.message === "User Detail Fetch Successfully") {
            setUserDetails(result.userDetail);
          } else {
            setMessage("Failed to fetch user data.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setMessage("An error occurred while fetching user data.");
        }
      }
    };
    fetchUserData();
  }, []);
  
  const [vehicleType, setVehicleType] = useState('');
  const [parkingNumber, setParkingNumber] = useState('');
  const [vehicleModel, setvehicleModel] = useState('');
  const [drivingLicenceNumber, setdrivingLicenceNumber] = useState('');
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const response = await Api.post('/parkings', {
        role: userDetails.role,
        name: userDetails.name,
        phone: userDetails.phone,
        email: userDetails.email,
        flat: userDetails.flat,
        tower: userDetails.tower,
        vehicleType,
        parkingNumber,
        vehicleModel,
        drivingLicenceNumber,
        remark,
      });
      
      if (response.data.message === "Form submitted successfully") {
        setSuccess(response.data.message);
        setVehicleType('');
        setParkingNumber('');
        setvehicleModel('');
        setdrivingLicenceNumber('');
        setRemark('');
      } else {
        setError(response.data.message || 'An error occurred during form submission.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  const [selectedOption, setSelectedOption] = useState('');
  const [showTextField, setShowTextField] = useState(false);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowTextField(selectedValue === 'other');
  };

  return (
    <>
      
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <Applyform />
          <div className="row mt-3 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12 mb-2"> 
                  <h5 className="radiant mt-2"> APPLY RFID TAG [Boom Barrier] </h5> 
                  <hr />
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4 col-12">
                    <label className="mt-1 mb-2">
                      <b>Do you have RFID TAG [Boom Barrier] ?</b> <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-lg-2 col-6">
                  <select
                        className="form-select "
                        id="exampleFormControlSelect1"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option value="">Select...</option>
                        <option value="option1">No</option>
                        <option value="other">Yes</option>
                      </select>
                  </div>
                 
                  {showTextField && (
                      <div className="col-lg-6 col-6">
                        <input
                          type="text"
                          className="form-control"
                          value={userDetails.rfid || ''}
                          id="exampleFormControlInput1"
                          placeholder="Enter Number"
                          disabled
                        />
                      </div>
                    )}
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label className="mt-2"><b>I am an/a</b> </label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Name</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="w-100 form-control text-uppercase" type="text" name="name" value={userDetails.name} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Phone</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <div className="row">
                          <div className="col-4">
                            <Code />
                          </div>
                          <div className="col-8">
                            <input className="w-100 form-control text-uppercase" type="text" name="phone" value={userDetails.phone} disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="account-fn" placeholder="Enter Email" value={userDetails.email} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-3">
                        <label htmlFor="account-fn"> <b>Flat Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="account-fn" placeholder="Enter Flat" value={userDetails.flat} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Tower</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="account-fn" value={userDetails.tower} disabled />
                      </div>
                    </div>
                  </div>
                 
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="parkingNumber"> <b>Parking Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control" type="text" id="parkingNumber" placeholder="Enter Parking Number" value={parkingNumber} onChange={(e) => setParkingNumber(e.target.value)} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="vehicleType"> <b>Vehicle Type</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <select name="vehicleType" className="form-select" value={vehicleType} onChange={(e) => setVehicleType(e.target.value)} required>
                          <option value="">Select Vehicle Type</option>
                          <option value="2 Wheeler">2 Wheeler</option>
                          <option value="4 Wheeler">4 Wheeler</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-5 col-12 mt-2">
                        <label htmlFor="vehicleModel"> <b>Vehicle Model No.</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-7 col-12 mt-2">
                        <input className="form-control" type="text" id="vehicleModel" placeholder="Enter Vehicle Model Details" value={vehicleModel} onChange={(e) => setvehicleModel(e.target.value)} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-5 col-12 mt-2">
                        <label htmlFor="drivingLicenceNumber"> <b>Driving Licence No.</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-7 col-12 mt-2">
                        <input className="form-control" type="text" id="drivingLicenceNumber" placeholder="Enter Driving Licence No." value={drivingLicenceNumber} onChange={(e) => setdrivingLicenceNumber(e.target.value)} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 mb-3 form-group">
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <label htmlFor="remark"> <b>Remarks:</b> </label>
                      </div>
                      <div className="col-lg-12 col-12 mt-2">
                        <textarea className="form-control" id="remark" name="remark" rows={3} value={remark} onChange={(e) => setRemark(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="row justify-content-end ">
                    <div className="col-lg-3 col-12 mb-3 mt-2">
                      <input className="bg-primary text-white form-control text-uppercase  w-100" type="submit" value="Submit" disabled={loading} />
                    </div>
                  </div>
                  {error && <div className="alert alert-danger text-center p-2 mt-2  ">{error}</div>}
                  {success && <div className="alert alert-success text-center p-2  mt-2 ">{success}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Urfid;
