
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Code from '../components/Code';
import { Helmet } from 'react-helmet';
import Api from '../components/Api';

const Login = ({ onLogin }) => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); 
    setLoading(true);
    setError(''); 

    try {
      const response = await Api.post('/alt-login', { phone, password });
      const { user, token, message } = response.data;

      console.log('Login response:', response.data); 

      if (user && token) {
       
        localStorage.setItem('userDetails', JSON.stringify(user));
        localStorage.setItem('token', token); 

        onLogin(user);

        console.log("Navigating to dashboard...");
        navigate('/dashboard');
      } else {
        setError('Invalid login response, please try again.');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Login failed';
      setError(errorMessage);

      // Redirect if the error message contains "Approval Pending"
      if (errorMessage.includes('Approval Pending')) {
        navigate('/account-approval');
      }
    } finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <Helmet>
        <title>Ajnara Daffodil Residents Login</title>
        <meta name="title" property="og:title" content="Ajnara Daffodil Residents Login" />
        <meta name="description" content="Sign in to your account at Ajnara Daffodil member login portal to access exclusive resident services and updates conveniently online." />
        <meta property="og:description" content="Sign in to your account at Ajnara Daffodil member login portal to access exclusive resident services and updates conveniently online." />
      </Helmet>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "body{background: rgb(63,94,251); background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);}\n.navbar {background-color: #babebf;} .hero-section{height: auto;}",
        }}
      />
      <Navbar />
      <div className="container hero-section">
        <div className="row justify-content-center p-2">
          <div className="col-lg-4 col-12 card p-4 bg-light">
            <div className="row">
              <div className="col-lg-6 col-6">
                <Link to="/login" className="btn btn-primary text-center w-100">
                  <i className="bi bi-box-arrow-in-right"></i> &nbsp; Login Here
                </Link>
              </div>
              <div className="col-lg-6 col-6">
                <Link to="/register" className="btn btn-secondary w-100">
                  <i className="bi bi-person-plus-fill"></i>&nbsp; Register
                </Link>
              </div>
            </div>
            <div className="loginForm">
              <form onSubmit={handleLogin}>
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <label htmlFor="phone">
                      <b>Phone Number</b> <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="row">
                      <div className="col-3">
                        <Code />
                      </div>
                      <div className="col-9">
                        <input
                          className="w-100 form-control"
                          type="text"
                          name="phone"
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Enter your Number"
                          value={phone}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <label htmlFor="password">
                      <b>Password</b> <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-12 mt-2">
                    <input
                      className="w-100 form-control"
                      type="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your Password"
                      value={password}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-4">
                    <Link to="/register">Sign-up Here?</Link>
                  </div>
                  <div className="col-6 mt-4 text-end">
                    <Link to="/forgot-password">Forgot password?</Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <button className="btn text-white mt-4 w-100 bg-primary form-control" type="submit" disabled={loading}>
                      {loading ? 'Logging in...' : 'Login'}
                    </button>
                  </div>
                  <div className="col-12 text-center mt-4 mb-3">
                    <Link to="/login" className="form-control p-1 text-primary bg-light">
                      <img width="23" height="23" src="https://img.icons8.com/color/48/google-logo.png" alt="google-logo" style={{ marginTop: '-5px' }} />
                      <span style={{ fontSize: '17px' }}> Login with Google</span>
                    </Link>
                  </div>
                </div>
                {error && (
                  <h1 style={{ fontSize: '15px', textAlign: 'center', marginTop: '20px', color: 'red' }}>
                    {error}
                  </h1>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
