import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Helmet } from 'react-helmet';
const Swimming = () =>{

    return(
        <>
         <Helmet>
        <title>Swimming Pool Rules And Regulations In Apartment </title>
        <meta name="title" property="og:title" content="Swimming Pool Rules And Regulations In Apartment " />
        <meta name="description" content="Guidelines for using shared amenities like the clubhouse, gym, swimming pool, and community hall are outlined in these rules. It " />
        <meta property="og:description" content="Guidelines for using shared amenities like the clubhouse, gym, swimming pool, and community hall are outlined in these rules. It " />
        </Helmet>
      <Navbar />
      <section className="hero-section" id="section_1">
          <div className="section-overlay" />
          <div className="video-wrap">
            <img src="assets/images/pool2.jpg" alt="" className="custom-video" id="imgym" />
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <h4 className="radiant text-uppercase ">Swimming Pool Rules &amp; Regulations </h4>
            <hr />
          </div>
        </section>
        <section>
          <div className="container">
            <p style={{textAlign: 'justify', fontWeight: 500}}>Swimming Pool Rules and Regulations in a Residential Society Come summer, a society’s swimming pool is an entertaining and vibrant hangout for children as well as adults. 
              But if managed poorly, your community swimming pool can become a hotbed for infections, 
              injuries and irritating behaviour. Follow this post to know about swimming pool rules and
              regulations for societies and keep your swimming pool a happy and safe place.
            </p>
            <p className="nmn" style={{textAlign: 'justify', fontWeight: 500}}>Swimming Pool Rules and Regulations in a Residential Society
              Come summer, a society’s swimming pool is an entertaining and vibrant hangout 
              for children as well as adults. But if managed poorly, your community swimming pool
              can become a hotbed for infections, injuries and irritating behaviour. Follow this post to know about swimming pool rules and regulations for 
              societies and keep your swimming pool a happy and safe place.</p> <br />
            <h3 style={{textAlign: 'justify', fontWeight: 500}}>Specifications</h3><br />
            <h5 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-2">1. Safety and rescue equipment </h5>
            <p style={{textAlign: 'justify', fontWeight: 600}}>A throw rope equal to the width of the pool attached to a ring buoy (life-saving floating devices) along with below-listed items:</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>1. A fully equipped first aid kit displayed and mounted within the pool premises</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>2. Resuscitation equipment (orange life jackets, rescue tubes, respirators, etc.)</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>2. Resuscitation equipment (orange life jackets, rescue tubes, respirators, etc.)</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>2. Emergency contact list</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>3. Chart for life-saving instructions</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>5. Depth markers indicating the depth of the pool in metres at shallow and deep ends, transition points and ‘no diving’ signs</p>
            <h4 style={{textAlign: 'justify', fontWeight: 500}} className="my-4">2. Water quality</h4>
            <p style={{textAlign: 'justify', fontWeight: 500}}>People, animals, debris and pathogens in the pool are the main source of contamination and at the same time, people themselves are at the risk of microbial and chemical hazards due to ingestion or surface contact.</p><br />
            <h4 style={{color: 'var(--e-global-color-vamtam_accent_6)'}}>Must require water standards</h4><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>1. pH level – between 7.2 and 8 (if higher than 8, swimmers will get skin rashes, if lower than 7, it can sting the eyes)</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>2. Calcium hardness – 200- 250 ppm for concrete pools and 175-225 ppm for vinyl pools</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>2. Alkalinity – 120 – 150ppm</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>3. Water clarity – a 4-inch b&amp;w object in the deepest end should be visible from the deck</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>5. Total coliforms – less than 10 in 100 millilitres</p>
            <p>6. Fecal coliform – 0</p>
            <h4 style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Disinfection of water</h4>
            <p style={{textAlign: 'justify', fontWeight: 500}}>A disinfectant should be quickly able to deactivate pathogens and microorganisms. Three common disinfectant types are chlorine-based (residual level 1.0-4.0 ppm), bromine-based (residual level 2.0-8.0 ppm) and ozone (concentration less 
              than 0.1 ppm) with the addition of UV radiation techniques and the use of algicides. </p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>For technical analysis and specifications, refer to Tamil Nadu State bye-laws, BIS Guidelines  
              and WHO Guidelines for a detailed understanding of recreational pool usage.</p><br />
            <h4 style={{color: 'var(--e-global-color-vamtam_accent_6)'}}>Pool care, operation and maintenance guidelines</h4><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. A society should hire a professionally qualified pool manager, lifeguard, swimming
              instructor and operations staff who
              are trained in handling chemicals and pool maintenance</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Bather load limits should be strictly enforced.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Pool operations timing and slot booking should be regulated as per pre-determined committee rules.
              A logbook (digital/physical) should be maintained so that any damages or mishaps can be accounted for.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Develop definitive policies for accidental discharge of faeces, bodily fluids or vomit and safe evacuation and disinfecting procedures thereof.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*.Follow manufacturer’s manual for operating and maintaining all equipment (filtration, recirculation pumps, valves, flowmeters, chlorinators feed pump tubing, injectors, perimeter overflow, skimmers, inlet-outlet fittings, hair and lint strainers, etc.)</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. There should be preventive checks and repairs of equipment; any cracks or damages inside and outside the pool should be promptly fixed.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Pool surfaces must be scrubbed daily to prevent slime, lint, dirt from settling in</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Decks must be rinsed daily, water must be drained and replaced weekly (or monthly if less used), indoor and outdoor decks should be disinfected weekly. </p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Shock treatments should be carried weekly basis of bather load and in case of rainfall, foul odours, extreme weather fluctuations, and algae growth.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Run the water circulation pump for 8 hours minimum to avoid cloudy bacterial water.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Brush the pool walls and use a pool vacuum to clean the pool floor weekly.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>*. Full-scale cleaning and maintenance should be done twice a year, especially after the monsoon.</p>
            <h2 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-5">General pool rules, etiquette and safety to follow</h2>
            <p style={{textAlign: 'justify', fontWeight: 500}}>According to the swimming pool rules and regulations for apartments, it is crucial that you:</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>1. Do not engage in a conversation with the lifeguard as it may divert his attention.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>2. Shower before and after swimming in the pool, do not use creams or lotions before entering the pool. Wear swimming attire, not regular clothes.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>3. Instruct children to walk, not run around the swimming pool. </p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>4. Adults should not engage in rough in or around the pool.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>5. No children under age 14 should be left unaccompanied while they’re in or outside the pool.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>6. Put on a swimming cap if you have long hair.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>7. Do not hang to the safety rope or play with it.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>8. Spitting, spouting water from nose and mouth should be against the rules.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>9. People with open cuts, wounds, bandages, injuries, cold, cough, fever, skin disease, venereal diseases and upset stomach must not be allowed to swim.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>10. Pool interior, premises and patio should not be littered with food wrappers, cigarettes or other trash. </p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>11. Safety equipment and pool appurtenances should not be played or tampered with. </p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>12. Inexperienced swimmers should not be allowed to use the diving board (without the instructor).</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>13. Toddlers must be made to wear swim-proof diapers.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>14. Animals and pets should not be permitted inside the pool.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>15. Glass bottles should not be allowed inside the pool. Do not enter the pool if you’re inebriated.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>16. Leave a towel on the lounge chair if you’re occupying it so that others know it’s taken.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>17. Play music at a reasonable volume so residents are not disturbed.</p>
            <p style={{textAlign: 'justify', fontWeight: 500}}>A residential society should circulate the pool rules and etiquette manual for the residents beforehand so that violations are prevented from occurring.</p><br />
            <p><b><span style={{fontStyle: 'italic'}}>Audit of a Co-operative Housing Society,
                  Sewage Treatment Plants (STP) and their Maintenance in Apartment Complexes,
                  What is Corpus Fund</span></b></p><br /><br />
          </div>
        </section>
      <Footer/>
        
        </>
    );
}
export default Swimming;