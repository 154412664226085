import React, { useEffect, useState } from "react";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Api from "../components/Api";

const Uevent = () => {
  const [eventPosts, setEventPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    const fetcheventPosts = async () => {
      try {
        const response = await Api.get("/user-events");
        setEventPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching event posts:", error);
      }
    };

    fetcheventPosts();
  }, []);

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  return (
    <>
     
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
            <div className="row">
            <div className='col-12 mb-2 bg-info'>
            <h4 className="radiant p-3">Our Society Events...</h4>
            </div>
            </div>
         
            <div className="container mb-5" >
            
              <div className="bg-white rounded p-3 mb-5">
            <div className="col-lg-12  col-12 " >
               <div className="row" >
                <div className="col-12" >
                  <table className="table table-bordered " >
                    <thead className="">
                      <tr>
                        <th className="text-center">Month</th>
                        <th>Title</th>
                      </tr>
                    </thead>
                    <tbody className=""  >
                      {eventPosts.map((post) => (
                        <tr key={post.id} onClick={() => handlePostClick(post)} style={{ cursor: "pointer" }} >
                          <td className="text-center">
                            <a id="a3" className="text-black" >{post.month || "dd/mm/yy"}</a></td>
                          <td className="">
                            <a  id="a3">{post.name}</a></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="row mt-4">
  <div className="col-lg-12 col-12 d-flex justify-content-end">
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className="page-item">
          <a className="page-link" href="#">Previous</a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">1</a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">2</a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">3...</a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</div>

                </div>

              </div>
            </div>
             
              </div>
            </div>

            
          </div>
      </div>

      <Footer />

      {/* Modal */}
      {selectedPost && (
        <div className="modal show fade d-block" role="dialog" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header bg-success">
              <div className="modal-title text-white" style={{
                fontSize: '25px'
                }} > Our Society Events... </div>
                 <button type="button" className="btn btn-danger" onClick={handleCloseModal} aria-label="Close">
                  <span className="ms-2 me-2"> Close </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 justify-contant-center">
                    <div className="product-image">
                    <img src={selectedPost.photo} alt={selectedPost.name} className="w-50 mb-3 " />
                    </div>
                  
                     
                     
                  </div>
                  <div className="col-10 mt-3">
                    <h5 className="radiant"> {selectedPost.name} </h5>
                  </div>
                  <div className="col-2 mt-3">
                    <p className="text-danger"> <b>{selectedPost.month}</b> </p>
                  </div>
                  <hr />
                  <div className="container mb-4 mt-2">
                  <div dangerouslySetInnerHTML={{ __html: selectedPost.description }}  style={{ textAlign: 'justify', }} /> 
                  </div>
                  
                </div>
                
              </div>
             
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Uevent;
