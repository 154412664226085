import React from "react";
import { Link } from "react-router-dom";
const Animities = () => {

  const isLoggedIn = !!localStorage.getItem('token');

  return (
    <>
      <section className="lifea">
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ backgroundColor: '#0e0e0e38' }}>
              <h2 className="text-danger text-center mt-3 ">Lifestyle Amenities</h2>
              <p style={{ color: 'white', fontWeight: 'normal', textAlign: 'center' }}>There are some unique
                facilities such as RO Water System,Conference Room...</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-12 mt-3 ">
              <div className="row" id="Amenities">
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/security-man.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Security</p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/lift.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Lift </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/park.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Park </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/paying-car-ticket_75737.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Parking </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/club.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Club </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/flower gardeen.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Gardens </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/jogging.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Jogging </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/kids play.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Kids Pool </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/backup.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Back Up </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/wifi.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Internet</p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/gym.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1">Gym</p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/water.png" alt="" width={40} className="mt-3" />
                  <p className="pi mt-1"> Ro Water</p>
                </div>
              </div>
              <a href data-bs-toggle="modal" data-bs-target="#exampleModal2" className="btn btn-danger mt-4 mb-4"> Show
                All 34 Amenities</a>
            </div>
            <div className="col-lg-5 col-12 mt-3 mb-4">
              <div className="box p-4 m-0 " style={{ backgroundColor: '#d3d3d3' }}>
                <h5 className="text-center">Club House Details</h5>
                <hr />
                <div className="row">
                  <div className="col-6">
                    <a href> 1. FRONT DESK </a>
                    <hr />
                    <a href > 2. LOBBY AREA </a>
                    <hr />
                    <a href > 3. PARTY HALL </a>
                    <hr />
                    <a href > 4. SITTING AREA </a>
                    <hr />
                  </div>
                  <div className="col-6">
                    <a href > 5. SWIMMING POOL </a>
                    <hr />
                    <a href > 6. PARTY HALL</a>
                    <hr />
                    <a href > 7. SPORTS ROOM</a>
                    <hr />
                    <a href> 8. GYM </a>
                    <hr />
                  </div>
                </div>
                <div className="col-12">
                  <Link to={isLoggedIn ? "/dashboard" : "/login"} className="text-danger">
                    See all &nbsp;<i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* POPUP Amenities */}
        <div className="modal fade" id="exampleModal2" tabIndex={-1} aria-labelledby="exampleModalLabel2" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header bg-info">
                <h2 className="radiant" id="exampleModalLabel2">Lifestyle Amenities</h2>
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"> <b>Close</b></button>
              </div>
              <div className="modal-body" style={{ backgroundColor: '#f3f3f3' }}>
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <li id="lte"> Multipurpose Hall</li>
                    <li id="lte">Earth quake resistant</li>
                    <li id="lte">Conference Room</li>
                    <li id="lte">Internet/Wi-Fi Connectivity</li>
                    <li id="lte"> Vaastu Compliant</li>
                    <li id="lte">Lift</li>
                    <li id="lte"> Security</li>
                    <li id="lte"> Activity Deck4</li>
                  </div>
                  <div className="col-lg-3 col-6">
                    <li id="lte"> Maintenance Staff</li>
                    <li id="lte">Banquet Hall</li>
                    <li id="lte">Intercom Facility</li>
                    <li id="lte"> Visitor Parking</li>
                    <li id="lte">Reserved Parking</li>
                    <li id="lte"> Laundry Service</li>
                    <li id="lte">Flower Gardens</li>
                    <li id="lte">Park</li>
                  </div>
                  <div className="col-lg-3 col-6">
                    <li id="lte">Indoor Squash &amp; Badminton Courts</li>
                    <li id="lte"> Meditation Area</li>
                    <li id="lte">Multipurpose Courts</li>
                    <li id="lte"> Contact Seller</li>
                    <li id="lte">New Launch</li>
                    <li id="lte">Kids Play Pool With Water Slides</li>
                    <li id="lte"> AEROBICS ROOM</li>
                  </div>
                  <div className="col-lg-3 col-6">
                    <li id="lte">Event Space &amp; Amphitheatre</li>
                    <li id="lte">Expert Reviews &amp; Advice</li>
                    <li id="lte"> Latest RERA Reports</li>
                    <li id="lte"> Fire Fighting Equipment</li>
                    <li id="lte"> Gymnasium</li>
                    <li id="lte">Water Storage</li>
                    <li id="lte">RO Water System</li>
                    <li id="lte"> Indoor Games Room</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Animities;