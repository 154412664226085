import React, { useState, useEffect } from 'react';
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Code from "../components/Code";
import Applyform from "../components/Applyform";
import Api from '../components/Api';


function Uparking() {
  const [userDetails, setUserDetails] = useState({});
  const [message, setMessage] = useState("");
  
  useEffect(() => {
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem("userDetails"))?.phone || "";
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          const result = response.data;
          if (result.message === "User Detail Fetch Successfully") {
            setUserDetails(result.userDetail);
          } else {
            setMessage("Failed to fetch user data.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setMessage("An error occurred while fetching user data.");
        }
      }
    };
    fetchUserData();
  }, []);

  const [formData, setFormData] = useState({
    parkingNumber: '',
    vehicleNo: '',
    vehicleModel: '',
    vehicleType: '',
    drivingLicenceNumber: '',
    drivingLicenceAttachment: null,
    remark: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'file' ? files[0] : value,
    }));
  };


  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append('role', userDetails.role);
    data.append('name', userDetails.name);
    data.append('phone', userDetails.phone);
    data.append('email', userDetails.email);
    data.append('flat', userDetails.flat);
    data.append('tower', userDetails.tower);
    data.append('parkingNumber', formData.parkingNumber); 
    data.append('vehicleType', formData.vehicleType);
    data.append('vehicleNo', formData.vehicleNo);
    data.append('vehicleModel', formData.vehicleModel);
    data.append('drivingLicenceNumber', formData.drivingLicenceNumber);
    data.append('drivingLicenceAttachment', formData.drivingLicenceAttachment);
    data.append('remark', formData.remark);

    try {
      setLoading(true); // Set loading to true while waiting for the response
      const response = await Api.post('/parkingsticker', data);
      
      // Set success message from the response
      setSuccess(response.data.message || 'Form submitted successfully!');
      setError(null);
    } catch (err) {
      // Set error message from the response or fallback to a generic message
      setError(err.response ? err.response.data.message : err.message);
      setSuccess(null);
    } finally {
      setLoading(false); // Reset loading state
    }
    
    
  };

  const [selectedOption, setSelectedOption] = useState('');
  const [showTextField, setShowTextField] = useState(false);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowTextField(selectedValue === 'other');
  };


  return (
    <>
     
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <Applyform />
          <div className="row mt-3 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12 mb-2">
                  <h5 className="radiant mt-2">Apply Parking Sticker</h5>
                 <hr />
                </div>
                <div className="row">
                    <div className="col-lg-4 col-12">

                      <label className="mt-1 mb-2">
                        <b> Do you have an old Parking Sticker ? </b> <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-lg-2 col-6">
                  <select
                        className="form-select "
                        id="exampleFormControlSelect1"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option value="">Select...</option>
                        <option value="option1">No</option>
                        <option value="other">Yes</option>
                      </select>
                  </div>
                 
                  {showTextField && (
                      <div className="col-lg-6 col-6">
                        <input
                          type="text"
                          className="form-control"
                          value={userDetails.parking_sticker_number || ''}
                          id="exampleFormControlInput1"
                          placeholder="Enter Number"
                          disabled
                        />
                      </div>
                    )}
                  </div>
              
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label className="mt-2"><b>I am an/a</b></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="name"><b>Name</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="w-100 form-control text-uppercase" type="text" name="name" value={userDetails.name} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="phone"><b>Phone</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <div className="row">
                          <div className="col-4">
                            <Code />
                          </div>
                          <div className="col-8">
                            <input className="w-100 form-control text-uppercase" type="text" name="phone" value={userDetails.phone} disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="email"><b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="email" name="email" placeholder="Enter Email" value={userDetails.email} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-3">
                        <label htmlFor="flat"><b>Flat Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="flat" name="flat" placeholder="Enter Flat" value={userDetails.flat} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="tower"><b>Tower</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="tower" name="tower" value={userDetails.tower} onChange={handleChange} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="parkingNumber"><b>Parking Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control" type="text" id="parkingNumber" name="parkingNumber" placeholder="Enter Parking Number" value={formData.parkingNumber} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
  <div className="row">
    <div className="col-lg-4 col-12 mt-2">
      <label htmlFor="vehicleType"><b>Vehicle Type</b> <span className="text-danger">*</span></label>
    </div>
    <div className="col-lg-8 col-12 mt-2">
      <select
        name="vehicleType"
        className="form-select"
        value={formData.vehicleType} // Bind to formData
        onChange={handleChange} // Handle change event
        required
      >
        <option value="">Select Vehicle Type</option>
        <option value="2 Wheeler">2 Wheeler</option>
        <option value="4 Wheeler">4 Wheeler</option>
      </select>
    </div>
  </div>
</div>



                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="vehicleNo"><b>Vehicle No</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control" type="text" id="vehicleNo" name="vehicleNo"
                         placeholder="Enter Vehicle No" value={formData.vehicleNo} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="vehicleModel"><b>Vehicle Model</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control" type="text" id="vehicleModel" name="vehicleModel" placeholder="Enter Vehicle Model" value={formData.vehicleModel} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="drivingLicenceNumber"><b>Driving Licence Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input className="form-control" type="text" id="drivingLicenceNumber" name="drivingLicenceNumber" placeholder="Enter Driving Licence Number" value={formData.drivingLicenceNumber} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-5 col-12 mt-2">
                        <label htmlFor="drivingLicenceAttachment"><b>Proof of Parking No.</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-7 col-12 mt-2">
                        <input className="form-control w-100" type="file" id="drivingLicenceAttachment" name="drivingLicenceAttachment" onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="drivingLicenceAttachment"><b>Proof of Driving Licence No.</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input className="form-control w-100" type="file" id="drivingLicenceAttachment" name="drivingLicenceAttachment" onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 mb-3 form-group">
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <label htmlFor="remark"><b>Remark:</b></label>
                      </div>
                      <div className="col-12 mt-1">
                        <textarea className="form-control" id="remark" name="remark" rows={3} value={formData.remark} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                
                </div>
                <div className="row mb-3">
                    <div className="col-lg-3 col-12">
                      <input className="bg-primary text-white form-control" type="submit" value="Submit" disabled={loading} />
                    </div>
                  </div>
                  
                  {error && <div className="alert alert-danger text-center p-2 mt-2  ">{error}</div>}
                  {success && <div className="alert alert-success text-center p-2  mt-2 ">{success}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Uparking;
