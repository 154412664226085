import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./pages/Home";
import Swimming from "./pages/Swimming";
import Gym from "./pages/Gym";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Verify from "./pages/Verify";
import Offer from "./pages/Offer";
import Pending from "./pages/Pending";
import ForgotPwd from "./pages/ForgotPwd";
import NotFoundPage from "./pages/NotFoundPage";
import Homepost from "./components/Homepost";
import Private from "./components/Private";
import Dashboard from "./users/Dashboard";
import Uprofile from "./users/Uprofile";
import Uevent from "./users/Uevent";
import Urules from "./users/Urules";
import Ucom from "./users/Ucom";
import Uinbox from "./users/Uinbox";
import Ucard from "./users/Ucard";
import Urfid from "./users/Urfid";
import Uparking from "./users/Uparking";
import Upet from "./users/Upet";
import Uhall from "./users/Uhall";
import Ustaff from "./users/Ustaff";
import Uvoting from "./users/Uvoting";
import Uexit from "./users/Uexit";
import Updatepwd from "./users/Updatepwd";
import Bill from "./users/Bill";
import Report from "./users/Report";
import Visitor from "./users/Visitor";
import Complaint from "./users/Complaint";
import Navbar from './components/Navbar';



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Load user from localStorage if exists
    const storedUser = localStorage.getItem('userDetails');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogin = (loggedInUser) => {
    setUser(loggedInUser);
  };

  return (
    <div className="App">
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/register" element={<Register />} /> 
        <Route path="/account-approval" element={<Pending />} />
        <Route path="/swimming-pool-rules-and-regulations" element={<Swimming />} />
        <Route path="/gym-rules-and-regulations" element={<Gym />} />       
        <Route path="/security-verification" element={<Verify />} />
        <Route path="/offer" element={<Offer />} />       
        <Route path="/rules-and-regulations" element={<Homepost />} />
        <Route path="/forgot-password" element={<ForgotPwd />} />
        

        {/* Protected Routes */}
        <Route path='/navbar'   element={ <Private><Navbar /> </Private> } />
        <Route  path="/dashboard" element={ <Private><Dashboard /> </Private> } />
        <Route path="/my-account" element={<Private><Uprofile /></Private>} />
        <Route path="/society-events" element={<Private><Uevent /></Private>} />
        <Route path="/rules-regulations" element={<Private><Urules /></Private>} />
        <Route path="/communication-update" element={<Private><Ucom /></Private>} />
        <Route path="/inbox" element={<Private><Uinbox /></Private>} />
        <Route path="/access-card" element={<Private><Ucard /></Private>} />
        <Route path="/rfid-tag" element={<Private><Urfid /></Private>} />
        <Route path="/parking-sticker" element={<Private><Uparking /></Private>} />
        <Route path="/pet-register" element={<Private><Upet /></Private>} />
        <Route path="/booking-society-area" element={<Private><Uhall /></Private>} />
        <Route path="/staff-report" element={<Private><Ustaff /></Private>} />
        <Route path="/voting" element={<Private><Uvoting /></Private>} />
        <Route path="/exit-management" element={<Private><Uexit /></Private>} />
        <Route path="/change-password" element={<Private><Updatepwd /></Private>} />
        <Route path="/bill-payment" element={<Private><Bill /></Private>} />
        <Route path="/bill-report" element={<Private><Report /></Private>} />
        <Route path="/visitor-management" element={<Private><Visitor /></Private>} />
        <Route path="/complaint-management" element={<Private><Complaint /></Private>} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </div>
  );
};

export default App;

